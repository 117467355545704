<template>
  <select class="form-select" data-hide-search="true" :id="id" :name="name" :disabled="disabled" :required="required"></select>
</template>

<script>
import $ from 'jquery';
import 'select2';

$.fn.select2.defaults.set("theme", "bootstrap5");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("selectionCssClass", ":all:");
$.fn.select2.defaults.set("containerCssClass", ":all:");

export default {
  name: 'Selector2',
  data() {
    return {
      select2: null
    };
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: [String, Array, Number], // previously was `value: String`
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Nothing Selected'
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    options: {
      handler(val) {
        this.setOption(val);
      },
      deep: true
    },
    modelValue: {
      handler(val) {
        this.setValue(val);
      },
      deep: true
    },
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        minimumResultsForSearch: Infinity,
        ...this.settings,
        data: val
      });
      this.setValue(this.modelValue);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger('change');
    }
  },
  mounted() {
    this.select2 = $(this.$el)
      //.find('select')
      .select2({
        placeholder: this.placeholder,
        minimumResultsForSearch: Infinity,
        ...this.settings,
        data: this.options
      })
      .on('select2:select select2:unselect', ev => {
        this.$emit('update:modelValue', this.select2.val());
        this.$emit('select', ev['params']['data']);
      });
    this.setValue(this.modelValue);
  },
  beforeUnmount() {
    this.select2.select2('destroy');
  }
};
</script>

<style>
.select2-container .select2-selection--single {
  height: unset !important;
}
</style>
